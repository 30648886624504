import { datadogLogs } from '@datadog/browser-logs'
import axios from 'axios'
import base64 from 'base-64'
import utf8 from 'utf8'
import { getUserStorage } from '../functions/storage'
import { findErrorMessage } from '../utils/findErrorMessages'
import { logout } from './auth'

const baseURL = process.env.GATSBY_API_URL

const api = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json; charset=utf-8'
	}
})

api.interceptors.request.use(
	async config => {
		let token
		const user = getUserStorage()
		const authorization = config.headers.Authorization

		if (!authorization) {
			if (user) {
				token = user.token || ''
				config.headers.Authorization = token
			}
		}

		config.headers.client = process.env.GATSBY_CLIENT_ID
		// config.headers['a-authorization-token'] = ''

		try {
			if (config.data) {
				Object.keys(config.data).forEach(key => {
					if (typeof config.data[key] === 'string') config.data[key] = config.data[key].trim()
				})
			}
		} catch {}
		const bytesEncoded = utf8.encode(JSON.stringify(config))
		const brand =
			window.localStorage.getItem('marca') === 'bradesco' ? 'SAUDE-DIGITAL' : 'CUIDAR-DIGITAL'
		datadogLogs.logger.warn(`REQUEST ${brand}`, base64.encode(bytesEncoded))
		return config
	},
	response => Promise.reject(response)
)

api.interceptors.response.use(
	response => {
		const res = findErrorMessage(response)
		if (JSON.stringify(response).includes('Sessão expirada')) setTimeout(() => logout(), 2000)
		return res
	},
	error => {
		const errorWithParsedMessage = { ...error, response: findErrorMessage(error.response) }
		// TODO uma forma de automatizar as funcoes que precisam
		// ser executadas de acordo com certas mensagens
		if (JSON.stringify(error.response).includes('Sessão expirada')) setTimeout(() => logout(), 2000)
		try {
			if (errorWithParsedMessage.response.data.attributes.originData.error.message)
				errorWithParsedMessage.response.data.message = `${errorWithParsedMessage.response.data.message}/n${errorWithParsedMessage.response.data.attributes.originData.error.message[0].message}`
		} catch {}
		return errorWithParsedMessage.response
	}
)

export default api
