import styled from 'styled-components'

interface IGrid {
	fullWidth?: boolean
	padding?: string
	rowGap?: string
	background?: string
	rowMinMax?: string
	columnGap?: string
	minHeight?: string
	margin?: string
	overflow?: string
	gridRow?: string
	templateRows?: string
	width?: string
}
export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, [col-start] 1fr);
	${({ templateRows }) => templateRows && `grid-template-rows: ${templateRows};`}
	grid-column-gap: ${({ columnGap }) => columnGap || '1em'};
	overflow: ${props => props.overflow || 'hidden'};
	grid-row-gap: ${({ rowGap }: IGrid) => rowGap || '1em'};
	${({ fullWidth }) => fullWidth && 'width: 100%'};
	${({ padding: p }: IGrid) => p && `padding:${p}`};
	${({ background }: IGrid) => background && `background:${background}`};
	${({ rowMinMax }) => rowMinMax && `grid-template-rows: minmax(${rowMinMax}, 1fr);`}
	${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
	${({ margin }) => margin && `margin: ${margin};`}
	${({ gridRow }) => gridRow && `grid-row: : ${gridRow};`}
	${({ width }) => width && `width: ${width};`}

	@media (max-width: 480px) {
		grid-row-gap: ${({ rowGap }: IGrid) => rowGap || '0'};
	}
`

interface ISize {
	size?:
		| '1'
		| '2'
		| '3'
		| '4'
		| '5'
		| '6'
		| '7'
		| '8'
		| '9'
		| '10'
		| '11'
		| '12'
		| '1/-1'
		| '2/-1'
		| '3/-1'
		| '1/-2'
		| '2/-2'
		| '3/-2'
		| '4/-1'
		| '8/-3'
	tablet?:
		| '1'
		| '2'
		| '3'
		| '4'
		| '5'
		| '6'
		| '7'
		| '8'
		| '9'
		| '10'
		| '11'
		| '12'
		| '1/-1'
		| '2/-1'
		| '3/-1'
		| '1/-2'
		| '2/-2'
		| '3/-2'
		| '4/-1'
		| '8/-3'
	hd?:
		| '1'
		| '2'
		| '3'
		| '4'
		| '5'
		| '6'
		| '7'
		| '8'
		| '9'
		| '10'
		| '11'
		| '12'
		| '1/-1'
		| '2/-1'
		| '3/-1'
		| '1/-2'
		| '2/-2'
		| '3/-2'
		| '4/-1'
		| '8/-3'
	fullHd?:
		| '1'
		| '2'
		| '3'
		| '4'
		| '5'
		| '6'
		| '7'
		| '8'
		| '9'
		| '10'
		| '11'
		| '12'
		| '1/-1'
		| '2/-1'
		| '3/-1'
		| '1/-2'
		| '2/-2'
		| '3/-2'
		| '4/-1'
		| '8/-3'
	lapTop?:
		| '1'
		| '2'
		| '3'
		| '4'
		| '5'
		| '6'
		| '7'
		| '8'
		| '9'
		| '10'
		| '11'
		| '12'
		| '1/-1'
		| '2/-1'
		| '3/-1'
		| '1/-2'
		| '2/-2'
		| '3/-2'
		| '4/-1'
		| '8/-3'
	mobile?:
		| '1'
		| '2'
		| '3'
		| '4'
		| '5'
		| '6'
		| '7'
		| '8'
		| '9'
		| '10'
		| '11'
		| '12'
		| '1/-1'
		| '2/-1'
		| '3/-1'
		| '1/-2'
		| '2/-2'
		| '3/-2'
		| '4/-1'
		| '8/-3'
}

const mountGridColumn = (colStart: string, size: string) => {
	const strColStart = colStart ? `col-start ${colStart} / ` : ''
	const span = size ? `span ${size}` : 'auto'

	return strColStart + span
}

interface ICol extends ISize {
	justifyContent?: 'space-between' | 'space-around' | 'center' | 'flex-end' | 'flex-start'
	xsmJustifyContent?: 'space-between' | 'space-around' | 'center' | 'flex-end' | 'flex-start'
	alingItems?: 'center' | 'flex-end' | 'flex-start'
	flexDirection?: 'column' | 'row'
	flex?: boolean
	mb?: string
	padding?: string
	pBigTablet?: string
	columnGap?: string
	margin?: string
	startFrom?: string
	overflow?: string
	width?: string
	startFromTablet?: string
	startFromMobile?: string
	rowGap?: string
}
export const Col = styled.div`
	grid-column: ${({ size, startFrom = '' }: ICol) => mountGridColumn(startFrom, size)};
	${({ flex }) => flex && 'display: flex;'}
	${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
	${({ alingItems }) => alingItems && `align-items: ${alingItems};`}
	${({ mb }) => mb && `margin-bottom: ${mb};`}
	${({ margin: m }) => m && `margin: ${m};`}
	${({ padding: p }) => p && `padding: ${p};`}
	${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
	${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap};`}
	${({ size, fullHd, tablet, mobile, lapTop, hd }) =>
		!(size || fullHd || tablet || mobile || lapTop || hd) && `overflow: hidden;`}
	${({ overflow }) => overflow && `overflow: ${overflow};`}
	${({ width }) => width && `width: ${width};`}
	${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap};`}
	${({ flexFlow }) => flexFlow && `flex-flow: ${flexFlow};`}


	@media (max-width: 1340px) {
		grid-column: ${({ fullHd, size, startFrom = '' }: ICol) =>
			mountGridColumn(startFrom, fullHd || size)};
	}

	@media (max-width: 1024px) {
		grid-column: ${({ hd, fullHd, size, startFrom = '' }: ICol) =>
			mountGridColumn(startFrom, hd || fullHd || size)};
		${({ pBigTablet }) => pBigTablet && `padding: ${pBigTablet};`}
	}

	@media (max-width: 1000px) {
		grid-column: ${({ lapTop, hd, fullHd, size, startFrom }: ICol) =>
			mountGridColumn(startFrom, lapTop || hd || fullHd || size)};
	}

	@media (max-width: 768px) {
		${({ psm }) => psm && `padding: ${psm};`}
		grid-column: ${({ startFromTablet, tablet, lapTop, hd, fullHd, size }: ICol) =>
			mountGridColumn(startFromTablet, tablet || lapTop || hd || fullHd || size)};
	}

	@media (max-width: 480px) {
		overflow: ${props => props.overflow || 'auto'};
		grid-column: ${({ mobile, startFromMobile }: ICol) =>
			mountGridColumn(startFromMobile, mobile || '12')};
	${({ psm }) => psm && `padding: ${psm};`}
	${({ xsmJustifyContent: psmJustifyContent }) =>
		psmJustifyContent && `justify-content: ${psmJustifyContent};`}

	}
`
