import ShowModal from '@cuidardigital/commons/components/Modal'
import React from 'react'
import styled from 'styled-components'
import { Col, Grid } from '@cuidardigital/commons/components/Grid/grid'
import IModalMessage from '@cuidardigital/commons/types/IModalMessage'
import { Span, Button } from '../superSetCommons'

import logotipo from '../../assets/img/logo.png'

export const ModalWrapper = styled.div`
	// padding: 0 28px;
`

export const ModalTitle = styled(Span)`
	display: block;
	margin-bottom: 10px;
	text-align: ${props => props.textAlign || 'left'};
	// font: Bold 32px/48px CentraleSans-Bold;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
`

export const ModalSubtitle = styled(Span)`
	display: block;
	line-height: 20px;
	margin-bottom: 10px;
	text-align: ${props => props.textAlign || 'left'};
	// font: 16px/24px CentraleSansRegular;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
`

const MessageModal = ({
	onClose,
	maxWidth = '',
	title = '',
	message = '',
	buttonText = '',
	secondButton,
	messageHTML,
	children,
	fontSize,
	textAlign,
	type,
	isLink = true,
	logo,
	buttonsSideBySide = false,
	noAction
}: IModalMessage) => {
	const closeModal = () => {
		onClose(false)
	}

	const messages = message.split('/n') || ['']

	return (
		<ShowModal
			maxWidth={maxWidth || '502px'}
			padTop='200px'
			zIndex='11'
			onClose={secondButton?.onClick || onClose}
		>
			<Grid>
				{logo && (
					<Col size='12' flex justifyContent='center'>
						<img src={logotipo} alt='' />
					</Col>
				)}
				<Col size='12' flex justifyContent='center'>
					{title && (
						<ModalTitle mb='8px' textAlign={textAlign} wrap bold fontSize={fontSize || '32px/40px'}>
							{title}
						</ModalTitle>
					)}
				</Col>
				<Col size='12'>
					{messageHTML ? (
						<ModalSubtitle
							wrap
							fontSize='14px/24px'
							dangerouslySetInnerHTML={{ __html: messageHTML }}
						/>
					) : (
						messages.map((msg, index) => (
							<ModalSubtitle textAlign={textAlign} wrap fontSize='14px/24px' key={index}>
								{msg}
							</ModalSubtitle>
						))
					)}
				</Col>
				<Col size='12' flex justifyContent='center'>
					{children}
				</Col>

				{!children &&
					(!buttonsSideBySide ? (
						<>
							{noAction ? (
								<></>
							) : (
								<Col size='12' flex justifyContent='center'>
									<Button halfWidth type={type || 'secundary'} onClick={closeModal}>
										{buttonText || 'Voltar'}
									</Button>
								</Col>
							)}{' '}
							<Col size='12' flex justifyContent='center'>
								{secondButton && (
									<Button
										halfWidth
										isLink={isLink}
										type={secondButton.type || 'secundary-void'}
										onClick={secondButton.onClick}
									>
										{secondButton.label}
									</Button>
								)}
							</Col>
						</>
					) : (
						<Col size='12' columnGap='20px' flex justifyContent='center'>
							{secondButton && (
								<Button
									halfWidth
									isLink={isLink}
									type={secondButton.type || 'secundary-void'}
									onClick={secondButton.onClick}
								>
									{secondButton.label}
								</Button>
							)}
							<Button halfWidth type={type || 'secundary'} onClick={closeModal}>
								{buttonText || 'Voltar'}
							</Button>
						</Col>
					))}
			</Grid>
		</ShowModal>
	)
}

export default MessageModal
