import messages from './errorMessages/messages.json'

export const findErrorMessage = (response: any, cbError?: () => void) => {
	try {
		const resJson = JSON.stringify(response)
		const error = Object.keys(messages).find(message => resJson.includes(message))
		if (error) {
			const res = { ...response }
			try {
				res.data.message = messages[error]
			} catch {}
			if (cbError) cbError()
			return res
		}
	} catch {
		return { data: { message: 'Sem objeto de erro' } }
	}

	return response
}
